var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center"
  }, [_c('p', [_vm._v(_vm._s(_vm.text !== undefined && _vm.text.length > _vm.size ? _vm.text.substr(0, _vm.size) + '..' : _vm.text))]), _c('svg', {
    class: "mx-1 ".concat(_vm.disable ? 'cursor-not-allowed' : 'cursor-pointer hover:opacity-80'),
    attrs: {
      "height": "".concat(_vm.height) || '20',
      "width": "".concat(_vm.width) || '20',
      "viewBox": "".concat(_vm.viewBox) || '0 0 20 20',
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    },
    on: {
      "click": function click($event) {
        return _vm.forAction();
      }
    }
  }, _vm._l(_vm.paths, function (item, index) {
    return _c('path', {
      key: index,
      attrs: {
        "d": item.d,
        "fill": "".concat(_vm.disable ? '#58595B' : item.fill) || '#000000',
        "fill-rule": item.fillRule,
        "clip-rule": item.clipRule
      }
    });
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }